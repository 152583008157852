import { fabric } from 'fabric';

type TextOption = {
  width?: number,
  selectable?: boolean,
  editable?: boolean,
  left?: number,
  top?: number,
  originX?: string,
  originY?:string,
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number | string;
  fontStyle?: "" | "normal" | "italic" | "oblique" | undefined;
  underline?: boolean;
  fill?: string;
  textAlign?: string
  setObjectActive?: boolean;
  clipPath?:fabric.Object|undefined;
  strokeWidth?:number|undefined;
  stroke?:string;

};

type TextDecorationType = "underline" | "overline" | "linethrough";

export const addText = (canvas: fabric.Canvas, text: string, options?: TextOption) => {
  const defaultStyle: TextOption = {
    width: 200,
    selectable:true,
    editable:true,
    fontFamily: 'Helvetica',
    fontSize: 20,
    fontWeight: 'normal',
    fontStyle: '',
    fill: '#000000',
  };

  const mergedStyle = { ...defaultStyle, ...options };

  const textbox = new fabric.Textbox(text, {
    width: mergedStyle.width,
    selectable: mergedStyle.selectable,
    editable: mergedStyle.editable,
    left: mergedStyle.left,
    top: mergedStyle.top,
    originX: mergedStyle.originX,
    originY: mergedStyle.originY,
    fontFamily: mergedStyle.fontFamily,
    fontSize: mergedStyle.fontSize,
    fontWeight: mergedStyle.fontWeight,
    fontStyle: mergedStyle.fontStyle || '',
    underline: mergedStyle.underline,
    fill: mergedStyle.fill,
    textAlign: mergedStyle.textAlign,
    clipPath:mergedStyle.clipPath||undefined,

  });



  canvas.add(textbox);

  if (options?.setObjectActive) {
    canvas.setActiveObject(textbox);
  }

  canvas.requestRenderAll();
};

export const setFontSize = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: number) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.fontSize = value;
    canvas?.requestRenderAll();
  }
};

export const setFontWeight = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: string) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.fontWeight = value;
    canvas?.requestRenderAll();
  }
};

export const setFontFamily = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: string) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.fontFamily = value;
    canvas?.requestRenderAll();
  }
};

export const setFontStyle = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: "" | "normal" | "italic" | "oblique" | undefined) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.fontStyle = value;
    canvas?.requestRenderAll();
  }
};

export const setUnderline = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: boolean) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set("underline", value);
    canvas?.requestRenderAll();
  }
};

export const setOverline = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: boolean) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set("overline", value);
    canvas?.requestRenderAll();
  }
};

export const setLinethrough = (canvas: fabric.Canvas, activeObject: fabric.Textbox, value: boolean) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set("linethrough", value);
    canvas?.requestRenderAll();
  }
};

export const setTextDecoration = (canvas: fabric.Canvas, activeObject: fabric.Textbox, type: TextDecorationType, value: boolean) => {
  if (activeObject instanceof fabric.Textbox) {
    switch (type) {
      case "underline":
        activeObject.set("underline", value);
        break;
      case "overline":
        activeObject.set("overline", value);
        break;
      case "linethrough":
        activeObject.set("linethrough", value);
        break;
      default:
        break;
    }
    canvas?.requestRenderAll();
  }
};

export const setFontColor = (canvas: fabric.Canvas, activeObject: fabric.Textbox, color: string) => {
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set("fill", color);
    canvas?.renderAll();
  }
};



export const flipTextX=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set('flipX', !activeObject.flipX);
    canvas.renderAll()
  }
}

export const flipTextY=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  if (activeObject instanceof fabric.Textbox) {
    activeObject.set('flipY', !activeObject.flipY);
    canvas.renderAll()
  }
}

export const rotateLeft=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  if (activeObject.angle || activeObject.angle===0) {
    activeObject.set('angle',(activeObject.angle-20))
    canvas.renderAll()
  }
  
}

export const rotateRight=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  if (activeObject.angle || activeObject.angle===0) {
    activeObject.set('angle',(activeObject.angle+20))
    canvas.renderAll()
  }
}

export const cloneObject=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  if (activeObject) {
    activeObject.clone(function (obj) {
      var vobj = obj;
      if (vobj && activeObject.top !==undefined)
      {
          vobj.set({
              top:activeObject.top + 30
          });
          canvas.add(vobj);
          canvas.renderAll();
          canvas.calcOffset();
      } 
  });
    canvas.renderAll()
  }
}


export const moveBackward=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  canvas.sendBackwards(activeObject)
  canvas.renderAll()
}
export const moveForward=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  canvas.bringForward(activeObject)
  canvas.renderAll()
}

export const bringToFront=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  canvas.bringToFront(activeObject)
  canvas.renderAll()
}

export const sendToBack=(canvas:fabric.Canvas,activeObject:fabric.Textbox)=>{
  canvas.sendToBack(activeObject)
  canvas.renderAll()
}



