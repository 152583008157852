//TDOD: Setup env variable for endpoints
const API_BASE_URL = "https://www.googleapis.com/webfonts/v1/webfonts";

export async function getGoogleFonts() {
    const googleFonts = await fetchGoogleFonts(1, 50);
    const newFonts = googleFonts.map((font) => [`${font.family}`, font.family]);
    return newFonts;
}

const fetchGoogleFonts = async (page: number = 1, perPage: number = 20) => {
  try {
    const response = await fetch(`${API_BASE_URL}?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}&sort=popularity&fields=items(family,variants,subsets)`);
    if (!response.ok) {
      throw new Error(`Failed to fetch Google Fonts: ${response.statusText}`);
    }
    const data = await response.json();
    const start = (page - 1) * perPage;
    const end = start + perPage;
    const fonts = data.items.slice(start, end).map((item: any) => ({
      family: item.family,
      variants: item.variants,
      subsets: item.subsets,
      category: item.category,
      provider: "google",
    }));
    return fonts;
  } catch (error) {
    console.error(error);
    return [];
  }
};
