import React, { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVectorSquare,
  faFileText,
  faGripLinesVertical,
} from "@fortawesome/free-solid-svg-icons";
interface SneakerProps {
  zoom: number;
  canvas: fabric.Canvas | null;
  isVisible: boolean;
  alertText: string | null;
  centerAreaWidth?:number;
  centerAreaHeight?:number;
  setCanvas: React.Dispatch<React.SetStateAction<fabric.Canvas | null>>;
  setBgSvg: React.Dispatch<
    React.SetStateAction<fabric.Object | fabric.Group | null>
  >;
  resetVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sneaker = ({
  zoom,
  canvas,
  isVisible,
  alertText,
  centerAreaHeight,
  centerAreaWidth,
  setCanvas,
  setBgSvg,
  resetVisibility,
}: SneakerProps) => {
  const canvas_2dref = useRef<HTMLCanvasElement>(null);
  const originalWidth = useRef<number | null>(null);
  const originalHeight = useRef<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [isPanningEnabled, setIsPanningEnabled] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showLabel, setShowLabel] = useState<boolean>(true);
  const [showFoldingLines, setShowFoldingLines] = useState<boolean>(true);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [localSvg, setLocalSvg] = useState<fabric.Object | fabric.Group | null>(
    null
  );

  useEffect(() => {
    if (isVisible) {
      setShowFoldingLines(true);
      setShowGrid(true);
      setShowLabel(true);
      resetVisibility(false);
    }
  }, [isVisible]);

  useEffect(() => {
    const sneakerCanvas = new fabric.Canvas(canvas_2dref.current, {
      backgroundColor: "white",
    });

    const sneakCan = document.getElementById("container");

    sneakCan?.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    sneakerCanvas.renderAll();

    fabric.loadSVGFromURL("/sneakers/sneaker_file.svg", (objects, options) => {
      const svgImage = fabric.util.groupSVGElements(objects, options);
      if (
        svgImage &&
        svgImage.width !== undefined &&
        svgImage.height !== undefined &&
        sneakerCanvas &&
        sneakerCanvas.width !== undefined &&
        sneakerCanvas.height !== undefined
      ) {
        let scale1 = sneakerCanvas.width / svgImage.width;
        let scale2 = sneakerCanvas.height / svgImage.height;
        svgImage.set({
          scaleX: scale1,
          scaleY: scale2,
          left: 0,
          top: 0,
          selectable: false,
          absolutePositioned: true,
          name: `panel`,
        });

        setBgSvg(svgImage);
        setLocalSvg(svgImage);

        sneakerCanvas.add(svgImage);

        sneakerCanvas?.on("after:render", function () {
          sneakerCanvas?.getObjects().forEach((obj) => {
            if (obj.name !== "panel") {
              obj.set({ clipPath: svgImage });
              obj.setCoords();
              console.log("Hello", obj);
            }
          });
        });
      }
    });

    setCanvas(sneakerCanvas);

    resizeCanvas(sneakerCanvas);
    originalWidth.current = sneakerCanvas.getWidth();
    originalHeight.current = sneakerCanvas.getHeight();

    // const handleResize = () => resizeCanvas(sneakerCanvas);

    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    //   sneakerCanvas.dispose();
    // };
  }, []);

//   useEffect(()=>{
// if(canvas){
//     resizeCanvas(canvas)
// }
//   },[centerAreaWidth])

  useEffect(() => {
    if (canvas) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        resizeCanvas(canvas); 
        canvas.renderAll()
      },20); 
      canvas.renderAll()

    }

    // Cleanup function to clear timeout on component unmount or when dependencies change
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [centerAreaWidth]);

  useEffect(() => {
    if (canvas) {
      let isDraggingLocal = false; // To track if dragging is active
      let lastPosXLocal = 0,
        lastPosYLocal = 0;

      // Handle mouse down event for panning
      const handleMouseDown = (event: any) => {
        if (isPanningEnabled === true) {
          isDraggingLocal = true;
          lastPosXLocal = event.e.clientX;
          lastPosYLocal = event.e.clientY;
          canvas.selection = false; // Disable selection while panning
        }
      };

      // Handle mouse move event for panning
      const handleMouseMove = (event: any) => {
        if (isPanningEnabled === true) {
          if (isDraggingLocal) {
            const deltaX = event.e.clientX - lastPosXLocal;
            const deltaY = event.e.clientY - lastPosYLocal;

            // Update the viewport transform for panning
            const vpt = canvas.viewportTransform;
            if (vpt) {
              vpt[4] += deltaX; // Update the X translation
              vpt[5] += deltaY; // Update the Y translation
              canvas.setViewportTransform(vpt); // Apply the new transform
            }

            lastPosXLocal = event.e.clientX; // Update last positions
            lastPosYLocal = event.e.clientY;
          }
        }
      };

      // Handle mouse up event to stop dragging
      const handleMouseUp = () => {
        isDraggingLocal = false; // Stop dragging
        canvas.selection = true; // Re-enable selection after panning
      };

      canvas.on("mouse:down", handleMouseDown);
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUp);

      return () => {
        canvas.off("mouse:down", handleMouseDown);
        canvas.off("mouse:move", handleMouseMove);
        canvas.off("mouse:up", handleMouseUp);
      };
    }
  }, [isPanningEnabled, canvas]);

  const resizeCanvas = (sneakerCanvas) => {

    if (wrapperRef.current && centerAreaWidth) {
      // const wrapperWidth = wrapperRef.current.offsetWidth;

      const wrapperWidth:number = centerAreaWidth*0.95;

      const wrapperHeight = wrapperRef.current.offsetHeight;
      let svgHeight = 1278;
      let svgWidth = 1639;
      let svgAspectRatio =
        svgHeight < svgWidth ? svgHeight / svgWidth : svgWidth / svgHeight;
      let ratioPercentage = svgAspectRatio;

      
      let newWidth = wrapperWidth;
      let newHeight = newWidth * ratioPercentage;

      // let newHeight=window.innerHeight*0.80;
      // let newWidth=newHeight*ratioPercentage;

      // console.log(wrapperRef.current,"WrapperHeight")
      // let newHeight = wrapperHeight;
      // let newWidth = wrapperHeight * ratioPercentage;

      // let ratioPercentage = window.innerWidth < 578 ? 0.65 : 0.88;
      // const newWidth = window.innerWidth * ratioPercentage;
      // const newHeight = newWidth * 0.733; // Adjust height based on desired aspect ratio

      sneakerCanvas.setWidth(newWidth);
      sneakerCanvas.setHeight(newHeight);

      if (originalHeight.current && originalWidth.current) {
        const scaleX = newWidth / originalWidth.current;
        const scaleY = newHeight / originalHeight.current;
        sneakerCanvas.getObjects().forEach((obj) => {
          obj.scaleX *= scaleX;
          obj.scaleY *= scaleY;
          obj.left *= scaleX;
          obj.top *= scaleY;
          obj.setCoords(); // Update object coordinates
        });
      }
      originalWidth.current = newWidth;
      originalHeight.current = newHeight;
      sneakerCanvas.renderAll();

    }
  };

  const handlePanningToggle = (event) => {
    setIsPanningEnabled(event.target.checked);
  };

  const handleGridChange = () => {
    setShowGrid(!showGrid);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if (
            (obj as any).id === "LEFT_SHOECUTTING_LINE_Image-2" ||
            (obj as any).id === "RIGHT_SHOE_CUTTING_LINE_Image-2" ||
            (obj as any).id === "LEFT_TONGUE_CUTTING_LINE_Image-2" ||
            (obj as any).id === "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2"
          ) {
            obj.set("visible", !obj.visible);
          }
        }
      });

      canvas?.renderAll();
    }
  };

  const handleLabelVisibility = () => {
    setShowLabel(!showLabel);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if (
          (obj as any).text &&
          (obj as any).text !== "FOLDING OUTSOLE & STRAP" &&
          (obj as any).text !== "STITCHES & EYELETS" &&
          (obj as any).text !== "CUTTING LINE"
        ) {
          obj.set("visible", !obj.visible);
        }
      });

      canvas?.renderAll();
    }
  };

  const handleFoldingLinesVisibility = () => {
    setShowFoldingLines(!showFoldingLines);
    if (localSvg instanceof fabric.Group) {
      localSvg._objects.forEach((obj: fabric.Object) => {
        if (
          (obj as any).id === "RIGHT_SHOE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
          (obj as any).id === "LEFT_SHOE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
          (obj as any).id === "LEFT_TONGUE_FOLDING_LINE_Image-2"
        ) {
          obj.set("visible", !obj.visible);
        }
      });

      canvas?.renderAll();
    }
  };

  useEffect(() => {
    if (canvas) {
      const center = canvas.getCenter();
      canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
      if (canvas.viewportTransform)
        canvas.setViewportTransform(canvas.viewportTransform);
    }
  }, [zoom, canvas]);

  return (
    <div className="mt-5">
      <div className="">
        <div className={`d-flex justify-content-center alertText ${alertText ? "alertBackground" : ""}`}>
          {alertText}
        </div>
        <div id="wrapper" className="" ref={wrapperRef}>
          <canvas ref={canvas_2dref} style={{ border: "1px solid #cccccc" }} id="my_cnvs"></canvas>
        </div>
      </div>

      <div className="my-2 d-flex justify-content-center">
        <button
          title="Cutting Lines"
          type="button"
          className={`btn-group btn mx-2 ${
            showGrid ? "btn-outline-secondary" : "btn-secondary"
          }`}
          onClick={() => {
            handleGridChange();
          }}
        >
          <FontAwesomeIcon icon={faVectorSquare} />
        </button>
        <button
          title="Text Labels"
          type="button"
          className={`btn-group btn mx-2 ${
            showLabel ? "btn-outline-secondary" : "btn-secondary"
          }`}
          onClick={() => {
            handleLabelVisibility();
          }}
        >
          <FontAwesomeIcon icon={faFileText} />
        </button>
        <button
          title="Folding Lines"
          type="button"
          className={`btn-group btn mx-2 ${
            showLabel ? "btn-outline-secondary" : "btn-secondary"
          }`}
          onClick={() => {
            handleFoldingLinesVisibility();
          }}
        >
          <FontAwesomeIcon icon={faGripLinesVertical} />
        </button>
      </div>


      <div className="my-2 d-flex justify-content-end">
        <label className="switch" title="Panning">
          <input type="checkbox" onChange={handlePanningToggle} checked={isPanningEnabled} />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default Sneaker;
