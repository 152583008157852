import React, { useState, useEffect } from "react";

import { fabric } from "fabric";
import WebFont from "webfontloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faArrowUpRightDots } from "@fortawesome/free-solid-svg-icons";
import { addText, setFontFamily } from "../../library/textActions";
import { getGoogleFonts } from "../../library/canvasFonts";

type TextPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;

};

const popularFonts = [
  ["Arial, sans-serif", "Arial"],
  ["Bevan, cursive", "Bevan"],
  ["Calibri, sans-serif", "Calibri"],
  ["Cinzel, serif", "Cinzel"],
  ["EB Garamond, serif", "EB Garamond"],
  ["Exo, sans-serif", "Exo"],
  ["Gentium Basic, serif", "Gentium Basic"],
  ["Lato, sans-serif", "Lato"],
  ["Open Sans, sans-serif", "Open Sans"],
  ["Palatino, serif", "Palatino"],
  ["Raleway, sans-serif", "Raleway"],
  ["Roboto Slab, serif", "Roboto Slab"],
  ["Sail, cursive", "Sail"],
  ["Alex Brus, cursive", "Alex Brush"],
  ["Lovers Quarrel, cursive", "Lovers Quarrel"],
  ["Oswald, sans-serif", "Oswald"],
  ["Montserrat Alternates, sans-serif", "Montserrat Alternates"],
  ["Nova Round, cursive", "Nova Round"],
  ["Supermercado One, cursive", "Supermercado One"],
  ["Playfair Display SC, serif", "Playfair Display SC"],
  ["Cutive, serif", "Cutive"],
  ["Rye, cursive", "Rye"],
  ["Crafty Girls, cursive", "Crafty Girls"],
  ["Metal Mania, cursive", "Metal Mania"],
  ["Metamorphous, cursive", "Metamorphous"],
  ['"Orbitron", sans-serif', "Orbitron"],
  ["VT323, monospace", "VT323"],
  ["Rock Salt, cursive", "Rock Salt"],
  ["Special Elite, cursive", "Special Elite"],
  ["Arya, sans-serif", "आर्या"],
  ["Halant, sans-serif", "हलन्त"],
];

const TextPanel: React.FC<TextPanelProps> = ({
  canvas,
  activeObject
}) => {
  const [fonts, setFonts] = useState(popularFonts);
  const [googleFonts, setGoogleFonts] = useState([]);
  const [fontFamily, _setFontFamily] = useState(
    (activeObject as fabric.Textbox)?.fontFamily || "Arial"
  );

  useEffect(() => {
    getGoogleFonts().then((fonts) => {
      setGoogleFonts(fonts);
    });

    WebFont.load({
      google: {
        families: [...fonts, ...googleFonts].map((font) => font[1]),
      },
      active: () => {
        console.log('Fonts are loaded!');
      },
    });
    
  }, []);

  // useEffect(() => {
  //   if (activeObject && activeObject instanceof fabric.Textbox) {
  //     _setFontFamily(activeObject.fontFamily || "Helvetica");
  //   }
  // }, [activeObject]);

  const handleTextClick = (fontFamily: string) => {
    if (canvas) {
      // if (activeObject && activeObject instanceof fabric.Textbox) {
      //   setFontFamily(canvas, activeObject, fontFamily);
      //   _setFontFamily(fontFamily);
      // }

      addText(canvas, "Add Text", {
        fontSize: 28,
        left:150,
        top:100,
        fill:"red",
        originX: "center",
        originY: "center",
        textAlign: "center",
        fontFamily: fontFamily,
        clipPath: undefined,
      });
    }
  };

  return (

    <div>
      <div className="accordion" id="accordionExample">
        {/* <div className="accordion-item">
          <h5 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <h5 className="pb-1 text-panel-heading">
                <FontAwesomeIcon icon={faFile} />
                <span className="px-2"> Document Font</span>
              </h5>
            </button>
          </h5>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div>{fontFamily}</div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item">
          <h5 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h5 className="pb-1 text-panel-heading">
                <FontAwesomeIcon icon={faArrowUpRightDots} />
                <span className="px-2"> Popular Fonts </span>
              </h5>
            </button>
          </h5>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="font-content">
                {fonts.map((family, index) => {
                  return (
                    <div key={index}>
                      <a
                        style={{ fontFamily: family[0] }}
                        onClick={() => handleTextClick(family[0])}
                      >
                        {family[1]}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h5 className="pb-1 text-panel-heading">
                <FontAwesomeIcon icon={faArrowUpRightDots} />
                <span className="px-2"> Google Fonts</span>
              </h5>
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div  className="font-content">
                {googleFonts.map((family, index) => {
                  return (
                    <div key={index}>
                      <a
                        style={{ fontFamily: family[0] }}
                        onClick={() => handleTextClick(family[0])}
                      >
                        {family[1]}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPanel;
