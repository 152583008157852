import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faImage,
  faCog,
  faPalette,
  faClose,
  faShapes,
  faLayerGroup,} from "@fortawesome/free-solid-svg-icons";
import TextPanel from "./TextPanel";
import ImagePanel from "./ImagePanel";
import ColorPanel from "./ColorPanel";
import ArtworkPanel from "./ArtworkPanel";
import LayerPanel from "./LayerPanel";

type SidebarProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  isCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  bgSvg:fabric.Object | fabric.Group|null;
};

const LeftSideBar: React.FC<SidebarProps> = ({
  canvas,
  activeObject,
  isCollapsed,
  bgSvg,
}) => {
  const [activePanel, setActivePanel] = useState("");
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const togglePanel = (panelName: string) => {
    setActivePanel(panelName);
  };

  const handleCollapse = (value: boolean) => {
    setCollapsed(value);
    isCollapsed(value);
  };

  useEffect(() => {
    if (activeObject instanceof fabric.Textbox) {
      togglePanel("text");
    }
  }, [activeObject]);

  return (
    <div className="container d-flex">
      <div className="d-flex">
        <div className=" left-sidebar-menu " >
          <div
            className="nav flex-column column-menu"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <div
              className="elements py-3"
              onClick={() => {
                handleCollapse(false);
              }}
            >
              <a
                className="nav-link  border-bottom active"
                id="v-pills-tab2"
                data-bs-toggle="pill"
                href="#v-pills-content2"
                role="tab"
                aria-controls="v-pills-content2"
                aria-selected="true"
              >
                <span className="">
                  <FontAwesomeIcon
                    icon={faFont}
                    size="2x"
                    className="d-block "
                  />
                  <p className="fw-light">Text</p>
                </span>
              </a>
            </div>

            <div
              className="elements py-3"
              onClick={() => {
                handleCollapse(false);
              }}
            >
              <a
                className="nav-link border-bottom"
                id="v-pills-tab3"
                data-bs-toggle="pill"
                href="#v-pills-content3"
                role="tab"
                aria-controls="v-pills-content3"
                aria-selected="false"
              >
                <span>
                  <FontAwesomeIcon
                    icon={faImage}
                    size="2x"
                    className="d-block"
                  />

                  <p className="fw-light">Image</p>
                </span>
              </a>
            </div>

            <div
              className="elements py-3 "
              onClick={() => {
                handleCollapse(false);
              }}
            >
              <a
                className="nav-link border-bottom"
                id="v-pills-tab4"
                data-bs-toggle="pill"
                href="#v-pills-content4"
                role="tab"
                aria-controls="v-pills-content4"
                aria-selected="false"
              >
                <span>
                  <FontAwesomeIcon
                    icon={faPalette}
                    size="2x"
                    className="d-block"
                  />
                  <p className="fw-light">Colors</p>
                </span>
              </a>
            </div>
            <div
              className="elements py-3 "
              onClick={() => {
                handleCollapse(false);
              }}
            >
              <a
                className="nav-link border-bottom"
                id="v-pills-tab5"
                data-bs-toggle="pill"
                href="#v-pills-content5"
                role="tab"
                aria-controls="v-pills-content5"
                aria-selected="false"
              >
                <span>
                  <FontAwesomeIcon
                    icon={faShapes}
                    size="2x"
                    className="d-block"
                  />
                  <p className="fw-light">Artwork</p>
                </span>
              </a>
            </div>
            <div
              className="elements py-3 "
              onClick={() => {
                handleCollapse(false);
              }}
            >
              <a
                className="nav-link border-bottom"
                id="v-pills-tab6"
                data-bs-toggle="pill"
                href="#v-pills-content6"
                role="tab"
                aria-controls="v-pills-content6"
                aria-selected="false"
              >
                <span>
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    size="2x"
                    className="d-block"
                  />
                  <p className="fw-light">Layers</p>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div
          className={`internalpanel ${collapsed ? "collapse" : null}`}
        >
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane active"
              id="v-pills-content2"
              role="tabpanel"
              aria-labelledby="v-pills-tab2"
            >
              <div onClick={() => handleCollapse(true)}>
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="d-block close-button py-2 "
                />
              </div>
              <TextPanel
                canvas={canvas}
                activeObject={activeObject}
              />
            </div>
            <div
              className="tab-pane fade "
              id="v-pills-content3"
              role="tabpanel"
              aria-labelledby="v-pills-tab3"
            >
              <div onClick={() => handleCollapse(true)}>
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="d-block close-button py-2"
                />
              </div>
              <ImagePanel
                canvas={canvas}
              />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-content4"
              role="tabpanel"
              aria-labelledby="v-pills-tab4"
            >
              <div onClick={() => handleCollapse(true)}>
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="d-block close-button py-2"
                />
              </div>
              <ColorPanel
                canvas={canvas}
                activeObject={activeObject}
                bgSvg={bgSvg}
              />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-content5"
              role="tabpanel"
              aria-labelledby="v-pills-tab4"
            >
              <div onClick={() => handleCollapse(true)}>
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="d-block close-button py-2"
                />
              </div>
              <ArtworkPanel
                canvas={canvas}
                activeObject={activeObject}
   

              />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-content6"
              role="tabpanel"
              aria-labelledby="v-pills-tab5"
            >
              <div onClick={() => handleCollapse(true)}>
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="d-block close-button py-2"
                />
              </div>
              <LayerPanel
                canvas={canvas}
                activeObject={activeObject}
  

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
