import React, { useState } from "react";
import { fabric } from "fabric";
import { imageArray } from "../cliparts";
import { useSelector } from "react-redux";

type ArtworkPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
};

const ArtworkPanel: React.FC<ArtworkPanelProps> = ({
  canvas,
  activeObject,
}) => {
  const handleArtwork = (event) => {
    let svgImageUrl = event.target;
    let scalefactor;
    fabric.loadSVGFromURL(svgImageUrl.src, (objects, options) => {
      const svgObject = fabric.util.groupSVGElements(objects, options);
      if (canvas && canvas.width && svgObject.width) {
        scalefactor = (canvas.width * 0.1) / svgObject.width;
        svgObject.set({
          scaleX: scalefactor,
          scaleY: scalefactor,
          top: 300,
          left: 150,
          originX: "center",
          originY: "center",
        });
        canvas.add(svgObject);
        canvas.renderAll();
      }
    });
  };

  return (
    <div className="container image-panel ">
      <div className="d-flex justify-content-center border-bottom pt-4">
        <h5 className="fw-light">Artwork</h5>
      </div>
      <div className="py-5 px-2 d-flex">
        {imageArray.map((imageUrl, index) => (
          <img
            onClick={handleArtwork}
            className="mx-2"
            key={index}
            src={imageUrl}
            alt={`${index}`}
            style={{ width: "50px", height: "50px" }}
          />
        ))}
      </div>
    </div>
  );
};

export default ArtworkPanel;
