import React, { useState, useRef } from "react";
import { fabric } from "fabric";

import { useSelector } from "react-redux";
import { addImageFromUrl } from "../../library/imageActions";
import ImageLibraries, {
  PixabayImage,
  PexelsImage,
} from "../../library/imageLibraries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type ImagePanelProps = {
  canvas: fabric.Canvas | null;
};

const ImagePanel: React.FC<ImagePanelProps> = ({
  canvas
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLibrary, setSelectedLibrary] = useState<"pixabay" | "pexels">(
    "pixabay"
  );
  const [imageList, setImageList] = useState<(PixabayImage | PexelsImage)[]>(
    []
  );

  const logoButtonRef = useRef<HTMLInputElement | null>(null);



  const searchImages = async () => {
    try {
      const imageLibrary = new ImageLibraries(selectedLibrary);
      const images = await imageLibrary.searchImages(searchQuery);
      setImageList(images);
    } catch (error) {
      console.error("Error searching images:", error);
    }
  };

  const handleAddImage = (image: PixabayImage | PexelsImage) => {
if (canvas) {
      addImageFromUrl(
        canvas,
        "webformatURL" in image ? image.webformatURL : image.src.original
      ); // Use the appropriate property based on the image source
    }
  };

  const handleGeneratedImage = (imageURL) => {
    if (canvas) {
      addImageFromUrl(canvas, imageURL); // Use the appropriate property based on the image source
    }
  };

  const handleAddClick = () => {
    if (logoButtonRef.current) logoButtonRef.current?.click();
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    let selectedImage;
    let scalingFactor;

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e && e.target !== null) selectedImage = e.target.result;
        fabric.Image.fromURL(selectedImage, function (img) {
          if (canvas && canvas.width !== undefined) {
            scalingFactor = canvas.width - canvas.width * 0.3;
            img.scaleToWidth(scalingFactor);
            img.scaleToHeight(scalingFactor);
            img.set({ top: 220 });
            canvas.add(img);
            canvas.setActiveObject(img);
            canvas.renderAll();
          }
        });
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="container image-panel ">
      <div className="d-flex justify-content-center border-bottom pt-4">
        <h5 className="fw-light">PHOTO</h5>
      </div>
      <ul className="nav nav-tabs" id="myTabs">
      <li className="nav-item">
          <a
            className="nav-link py-4 fw-light active"
            id="tab2-tab"
            data-bs-toggle="tab"
            href="#tab2"
          >
            UPLOAD
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link  py-4 fw-light"
            id="tab1-tab"
            data-bs-toggle="tab"
            href="#tab1"
          >
            IMAGES
          </a>
        </li>
   
      </ul>

      <div className="tab-content px-3 py-3" id="myTabContent">
        <div
          className="tab-pane fade"
          id="tab1"
          role="tabpanel"
          aria-labelledby="tab1-tab"
        >
          <div className="container-fluid">
            <div className="dropdown py-2 d-flex justify-content-center">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedLibrary === "pixabay" ? "Pixabay" : "Pexels"}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => setSelectedLibrary("pixabay")}
                >
                  Pixabay
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => setSelectedLibrary("pexels")}
                >
                  Pexels
                </a>
              </div>
            </div>

            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Search Images"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center my-3">
              <button
                className="btn-search my-2 fw-light border rounded-1"
                type="button"
                onClick={searchImages}
              >
                Search
              </button>
            </div>

            <div className="row row-cols-2 searchpanel">
              {imageList.map((image) => (
                <div key={image.id} className="col mb-4">
                  <img
                    src={
                      "webformatURL" in image
                        ? image.webformatURL
                        : image.src.original
                    }
                    alt={image.tags}
                    className="img-fluid"
                    onClick={() => handleAddImage(image)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade show active"
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab2-tab"
        >
          <div className="container-fluid">
            <div>
              <span
                id="openFileButton"
                className="nav-link text-center "
                onClick={handleAddClick}
              >
                <span className="addImageBtn" style={{ border: "1px solid black" }}>
                  Add Image
                </span>
              </span>
              <input
                onChange={handleLogoChange}
                ref={logoButtonRef}
                type="file"
                id="imageInput"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePanel;
