import React, { MutableRefObject } from "react";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  faRotateLeft,
  faRotateRight,
  faQuestionCircle,
  IconDefinition,
  faUserCircle,
  faUser,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import masterblocks from "./Images/logo.png";
import "./TopBar.css"; // import the CSS file
import CanvasEvents from "./library/canvasEvents";

type TopBarProps = {
  canvas: fabric.Canvas | null;
  canvasEventsRef: MutableRefObject<CanvasEvents | null>;
  bgSvg: fabric.Object | fabric.Group | null;
  resetVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertText: React.Dispatch<React.SetStateAction<string | null>>;
};

const TopBar: React.FC<TopBarProps> = ({
  canvas,
  canvasEventsRef,
  bgSvg,
  resetVisibility,
  setAlertText,
}) => {
  const [saveFileLoading, setSaveFileLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [alertFound, setAlertFound] = useState(false);

  const [tempProjectId, setTempProjectID] = useState<number | null>(null);
  const storeName = sessionStorage.getItem("storeName");
  const storeAccessToken = sessionStorage.getItem("storeAccessToken");

  const searchParams = new URLSearchParams(window.location.search);
  const shopifyId = searchParams.get("shopify_id");
  const paramsProject = searchParams.get("project_id");

  const handleUndo = () => {
    canvasEventsRef.current?.handleUndo();
  };

  const handleRedo = () => {
    canvasEventsRef.current?.handleRedo();
  };

  function saveFile(data, filename, isAlert) {
    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (
          (obj as any).text ||
          (obj as any).id === "LEFT_SHOECUTTING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_CUTTING_LINE_Image-2" ||
          (obj as any).id === "LEFT_TONGUE_CUTTING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
          (obj as any).id === "LEFT_SHOE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
          (obj as any).id === "LEFT_TONGUE_FOLDING_LINE_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_STITCH_Image-2" ||
          (obj as any).id === "RIGHT_SHOE_EYELETS_Image-2" ||
          (obj as any).id === "LEFT_SHOE_STITCH_Image-2" ||
          (obj as any).id === "LEFT_SHOE_EYELETS_Image-2" ||
          (obj as any).id === "STITCHES_EYELETS-RECTANGLE_Image-2" ||
          (obj as any).id === "CUTTING_FOLDING_LINE-RECTANGLE_Image-2" ||
          (obj as any).id === "FOLDING_OUTSOLE_STRAP-RECTANGLE_Image-2"
        ) {
          obj.set("visible", true);
        }
      });
      canvas?.renderAll();
      resetVisibility(true);
    }
    if (!isAlert) {
      setAlertText(null)
      var link = document.createElement("a");
      link.href = data;
      link.download = filename;

      link.click();
    }

  
  }
  const hexToRgba = (hex) => {
    var match = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.exec(hex);
    if (!match) {
      return null; // Invalid hex color
    }

    var color = match[1];
    if (color.length === 3) {
      // If it's a shorthand hex (e.g., #000), expand to six digits
      color = color
        .split("")
        .map(function (c) {
          return c + c;
        })
        .join("");
    }

    // Convert hex to RGBA
    var r = parseInt(color.substring(0, 2), 16);
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);
    var a = 255; // Default alpha is fully opaque

    return [r, g, b, a];
  };

  const exportImages = async () => {
    if (canvas) {
      canvas.discardActiveObject();
      setIsUpdating(true);

      const strDownloadMime = "image/octet-stream";
      let strMime = "image/png";
      let isAlert = false;

      const canvasElement = canvas.getElement();

      if (canvasElement) {
        if (bgSvg instanceof fabric.Group) {
          bgSvg._objects.forEach((obj: fabric.Object) => {
            if (
              (obj as any).text ||
              (obj as any).id === "LEFT_SHOECUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "LEFT_TONGUE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
              (obj as any).id === "LEFT_SHOE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
              (obj as any).id === "LEFT_TONGUE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_STITCH_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_EYELETS_Image-2" ||
              (obj as any).id === "LEFT_SHOE_STITCH_Image-2" ||
              (obj as any).id === "LEFT_SHOE_EYELETS_Image-2" ||
              (obj as any).id === "STITCHES_EYELETS-RECTANGLE_Image-2" ||
              (obj as any).id === "CUTTING_FOLDING_LINE-RECTANGLE_Image-2" ||
              (obj as any).id === "FOLDING_OUTSOLE_STRAP-RECTANGLE_Image-2"
            ) {
              obj.set("visible", false);
            }
          });
          canvas.renderAll();

          var rgba = hexToRgba("#000001");
          if (!rgba) {
            alert("Invalid color format");
            return;
          }

          const panel = canvas.getObjects().filter((obj) => {
            if (obj.name === "panel") return obj;
          });
          var canvasEle = canvas.getElement();
          var context = canvasEle.getContext("2d");

          let xPanel = panel[0].left;
          let yPanel = panel[0].top;
          let panelWidth = panel[0].width;
          let panelHeight = panel[0].height;
          console.log(xPanel,yPanel,panelWidth,panelHeight,"im")

          if (xPanel!==undefined && yPanel!==undefined && panelWidth!==undefined && panelHeight!==undefined) {

            var imageData = context?.getImageData(
              xPanel,
              yPanel,
              panelWidth,
              panelHeight
            );
            // var imageData = context?.getImageData(
            //   0,
            //   0,
            //   canvas.getWidth(),
            //   canvas.getHeight()
            // );
            if (imageData) {
              var data = imageData.data;

              for (var i = 0; i < data.length; i += 4) {
                var r = data[i]; // Red component
                var g = data[i + 1]; // Green component
                var b = data[i + 2]; // Blue component
                var a = data[i + 3]; // Alpha component

                // Now compare the pixel's RGBA to the target RGBA
                if (rgba)
                  if (
                    r === rgba[0] &&
                    g === rgba[1] &&
                    b === rgba[2] &&
                    a === rgba[3]
                  ) {
                    // alert('Color '+'#000001'+ ' found at pixel index: ' + (i / 4));
                    setAlertText("Base Color Found!");
                    isAlert = true;
                    canvas.renderAll();
                    break
                  }
              }
              setTimeout(() => {
                setAlertText(null);  // Clear the alert text after 5 seconds
              },3000);
              // alert('Color ' + '#000001' + ' is NOT present on the canvas.');
            }

            const imgData = canvasElement.toDataURL(strMime, 1.0);

            // Save the image as a file
            saveFile(
              imgData.replace(strMime, strDownloadMime),
              "sneaker.png",
              isAlert
            );

            // Optionally, if you want to send the image data somewhere
            const body = {
              image: imgData,
              fileName: "sneaker.png",
              shopify_id: shopifyId,
            };
          }
          // try {
          //   await axios.post(
          //     `/api/update/?store=${storeName}&access_token=${storeAccessToken}`,
          //     JSON.stringify(body),
          //     { headers: { "Content-Type": "application/json" } }
          //   );
          // } catch (error) {
          //   console.error("Upload failed:", error);
          // }
        }
      }

      setIsUpdating(false);
    }
  };

  const saveAsFile = async () => {};

  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid topbar mt-3 mb-2">
        <img
          className="logo align-items-center"
          src={masterblocks}
          alt="MasterBlocks"
        />
        <div title="Undo" className="flex-fill d-flex justify-content-center">
          <button className=" icon-container  rotate-left" onClick={handleUndo}>
            <FontAwesomeIcon icon={faRotateLeft} />
          </button>
          <button
            title="Redo"
            className="icon-container rotate-right mx-4"
            onClick={handleRedo}
          >
            <FontAwesomeIcon icon={faRotateRight} />
          </button>
        </div>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div
          className="  justify-content-end align-items-center"
          id="navbarTogglerDemo02"
        >
          <ul className="navbar-nav  mb-2 mb-lg-0">
            {/* <li className="nav-item mx-3">
              {saveFileLoading ? (
                <span>Saving...</span>
              ) : (
                <button
                  className="btn-topbar save"
                  onClick={() => {
                    saveAsFile();
                  }}
                >
                  Save As File
                </button>
              )}
            </li> */}
            <li className="nav-item mx-3">
              {isUpdating ? (
                <span>Saving...</span>
              ) : (
                <button
                  className="btn-topbar download"
                  onClick={() => {
                    exportImages();
                  }}
                >
                  Download
                </button>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
