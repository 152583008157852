import { fabric } from 'fabric';

interface HistoryItem {
  json: string;
  state: fabric.Object[];
}

class CanvasHistory {
  private canvas: fabric.Canvas;
  private history: HistoryItem[];
  private currentStateIndex: number;
  private maxHistoryLength: number;
  private extraProps: string[];
  public historyProcessing: boolean;

  constructor(canvas: fabric.Canvas, maxHistoryLength = 50) {
    this.canvas = canvas;
    this.history = [];
    this.currentStateIndex = -1;
    this.maxHistoryLength = maxHistoryLength;
    this.extraProps = ['selectable', 'editable', 'selected','name'];
    this.historyProcessing = false;
    
    // Set initiale state as the state when CanvasHistory class was initiated
    this.saveHistoryItem(this.getCurrentState(), this.canvas.getObjects());
  }

  getCurrentState() {
    return this.canvas.toDatalessJSON(this.extraProps);
  }

  saveHistoryItem(currentState, stateObj: fabric.Object[]): void {
    if (this.currentStateIndex !== this.history.length - 1) {
      this.history = this.history.slice(0, this.currentStateIndex + 1);
    }
    this.history.push({
      json: JSON.stringify(currentState),
      state: stateObj,
    });
    if (this.history.length > this.maxHistoryLength) {
      this.history.shift();
    }
    this.currentStateIndex = this.history.length - 1;
   
  }

  undo(): void {

    this.historyProcessing = true;
    if (this.currentStateIndex <= 0) {

      this.historyProcessing = false;
      return;
    }

    const currentState = this.history[this.currentStateIndex - 1];
    // console.log(currentState.json);
    this.canvas.loadFromJSON(currentState.json, () => {
      this.currentStateIndex--;
      this.canvas.renderAll();
      this.historyProcessing = false;
    });
  
  }

  redo(): void {
    this.historyProcessing = true;
    if (this.currentStateIndex === this.history.length - 1) {
      this.historyProcessing = false;
      return;
    }
    const currentState = this.history[this.currentStateIndex + 1];
    this.canvas.loadFromJSON(currentState.json, () => {
      this.currentStateIndex++;
      this.canvas.renderAll();
      this.historyProcessing = false;
    });
  }
}

export default CanvasHistory;
