import { fabric } from 'fabric';


type ImageOption = {
    left?: number;
    top?: number;
    scaleX?:number;
    scaleY?:number;
    originX?:string;
    originY?:string;
    clipPath?:fabric.Object|undefined;
};



export const addImageFromUrl = (canvas: fabric.Canvas, imageUrl: string, options?: ImageOption) => {




  
    fabric.Image.fromURL(imageUrl, (img) => {
        const mergedOptions = { ...options };
          if(mergedOptions.left && mergedOptions.top)
        img.set({
            left: mergedOptions.left,
            top: mergedOptions.top,
            scaleX:mergedOptions.scaleX,
            scaleY:mergedOptions.scaleY,
            clipPath:mergedOptions.clipPath,
        });

        img.set({originX: "center",
        originY: "center",})
        canvas.add(img);
        canvas.requestRenderAll();
    },{ crossOrigin: 'anonymous' });
  }


export const flipImageHorizontally = (canvas: fabric.Canvas, activeObject: fabric.Image) => {
    if (activeObject instanceof fabric.Image) {
        activeObject.flipX = !activeObject.flipX;
        canvas.requestRenderAll();
    }
};




export const flipImageVertically = (canvas: fabric.Canvas, activeObject: fabric.Image) => {
    if (activeObject instanceof fabric.Image) {
        activeObject.flipY = !activeObject.flipY;
        canvas.requestRenderAll();
    }
};



export const flipImageX=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if (activeObject instanceof fabric.Image) {
      activeObject.set('flipX', !activeObject.flipX);
      canvas.renderAll()
    }
  }
  
 
    
  export const flipImageY=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if (activeObject instanceof fabric.Image) {
      activeObject.set('flipY', !activeObject.flipY);
      canvas.renderAll()
    }
  }
  
  export const rotateLeft=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if (activeObject.angle || activeObject.angle===0) {
      activeObject.set('angle',(activeObject.angle-20))
      canvas.renderAll()
    }
    
  }
  
  export const rotateRight=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if (activeObject.angle || activeObject.angle===0) {
      activeObject.set('angle',(activeObject.angle+20))
      canvas.renderAll()
    }
  }
  
  export const cloneObject=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if (activeObject) {
      activeObject.clone(function (obj) {
        var vobj = obj;
        if (vobj && activeObject.top !==undefined)
        {
            vobj.set({
                top:activeObject.top + 30
            });
            canvas.add(vobj);
            canvas.renderAll();
            canvas.calcOffset();
        } 
    });
      canvas.renderAll()
    }
  }

  export const moveBackward=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    if(canvas){
      let ob=canvas.getActiveObject()
   if(ob)
  canvas.sendBackwards(ob);
    // activeObject.setCoords();
    canvas.renderAll()
    }
  }
  export const moveForward=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    let ob=canvas.getActiveObject()
    if(ob)
    canvas.bringForward(ob)
    canvas.renderAll()
  }

  export const bringToFront=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    canvas.bringToFront(activeObject)
    canvas.renderAll()
  }

  export const sendToBack=(canvas:fabric.Canvas,activeObject:fabric.Image)=>{
    canvas.sendToBack(activeObject)
    canvas.renderAll()
  }