import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';

interface Props {
  color: string;
  onChange: (color: string) => void;
}

const ColorPicker: React.FC<Props> = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor: ColorResult) => {
    onChange(newColor.hex);
  };

  return (
    <div>
      <div
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '2px',
          backgroundColor: color,
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            onClick={handleClose}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
