import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";

type RightSideProps = {
  canvas: fabric.Canvas | null;
  bgSvg: fabric.Object | fabric.Group | null;
  resetVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const RightSideBar: React.FC<RightSideProps> = ({
  canvas,
  bgSvg,
  resetVisibility,
}) => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [isOverlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const exportImages = async () => {
    if (canvas) {
      canvas.discardActiveObject();
      const strDownloadMime = "image/octet-stream";
      const strMime = "image/png";
      const canvasElement = canvas.getElement();

      if (canvasElement) {
        if (bgSvg instanceof fabric.Group) {
          bgSvg._objects.forEach((obj: fabric.Object) => {
            if (
              (obj as any).text ||
              (obj as any).id === "LEFT_SHOECUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "LEFT_TONGUE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
              (obj as any).id === "LEFT_SHOE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
              (obj as any).id === "LEFT_TONGUE_FOLDING_LINE_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_STITCH_Image-2" ||
              (obj as any).id === "RIGHT_SHOE_EYELETS_Image-2" ||
              (obj as any).id === "LEFT_SHOE_STITCH_Image-2" ||
              (obj as any).id === "LEFT_SHOE_EYELETS_Image-2" ||
              (obj as any).id === "STITCHES_EYELETS-RECTANGLE_Image-2" ||
              (obj as any).id === "CUTTING_FOLDING_LINE-RECTANGLE_Image-2" ||
              (obj as any).id === "FOLDING_OUTSOLE_STRAP-RECTANGLE_Image-2"
            ) {
              obj.set("visible", false);
            }
          });
          canvas.renderAll();

          const imgData = canvasElement.toDataURL(strMime, 1.0);
          let data = imgData.replace(strMime, strDownloadMime);
          if (data) {
            setImageData(data);
          }

          // Restore the visibility of hidden elements
          if (bgSvg instanceof fabric.Group) {
            bgSvg._objects.forEach((obj: fabric.Object) => {
              if (
                (obj as any).text ||
                (obj as any).id === "LEFT_SHOECUTTING_LINE_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_CUTTING_LINE_Image-2" ||
                (obj as any).id === "LEFT_TONGUE_CUTTING_LINE_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_FOLDING_LINE_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2" ||
                (obj as any).id ===
                  "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
                (obj as any).id === "LEFT_SHOE_FOLDING_LINE_Image-2" ||
                (obj as any).id === "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2" ||
                (obj as any).id === "LEFT_TONGUE_FOLDING_LINE_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_STITCH_Image-2" ||
                (obj as any).id === "RIGHT_SHOE_EYELETS_Image-2" ||
                (obj as any).id === "LEFT_SHOE_STITCH_Image-2" ||
                (obj as any).id === "LEFT_SHOE_EYELETS_Image-2" ||
                (obj as any).id === "STITCHES_EYELETS-RECTANGLE_Image-2" ||
                (obj as any).id === "CUTTING_FOLDING_LINE-RECTANGLE_Image-2" ||
                (obj as any).id === "FOLDING_OUTSOLE_STRAP-RECTANGLE_Image-2"
              ) {
                obj.set("visible", true);
              }
            });
            canvas?.renderAll();
            resetVisibility(true);
          }
        }
      }
    }
  };

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc); // Set the image to be displayed in the overlay
    setOverlayVisible(true); // Show the overlay
  };

  const handleCloseOverlay = () => {
    setOverlayVisible(false); // Hide the overlay
    setSelectedImage(null); // Reset the selected image
  };


  return (
    <div>
      <div className="">
        <div
          className="drawer-content"
      
        >
          <div className="p-4">
            <h2 className="d-flex justify-content-center">Mock Up Images</h2>
            <div className="justify-content-center d-flex">
              <button className="btn btn-secondary my-3" onClick={exportImages}>
                Load Mockup Image
              </button>
            </div>
            <div className="row">
              {imageData ? (
                <div
                  className="image-item col-12"
                  onClick={() => handleImageClick(imageData)}
                >
                  <img src={imageData} alt="Canvas Image" />
                </div>
              ) : (
                <div>Please Load Mockups!</div>
              )}
            </div>
          </div>
        </div>

        {isOverlayVisible && selectedImage && (
          <div className="overlay" onClick={handleCloseOverlay}>
            <div
              className="overlay-content justify-content-center d-flex"
              onClick={(e) => e.stopPropagation()}
            >
              <img src={selectedImage} alt="Selected Canvas Image" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSideBar;
