import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { setBgColor } from "../../library/colorAction";
import ColorPicker from "../ColorPicker";
import { useSelector } from "react-redux";

type ColorPanelProps = {
  canvas: fabric.Canvas | null;
  activeObject: fabric.Object | null;
  bgSvg: fabric.Object | fabric.Group | null;
};

const ColorPanel: React.FC<ColorPanelProps> = ({
  canvas,
  activeObject,
  bgSvg,
}) => {
  const [productColor, setProductColor] = useState("#FFFFFF");
  const [leftTongueColor, setLeftTongueColor] = useState("#FFFFFF");
  const [rightongueColor, setRightTongueColor] = useState("#FFFFFF");
  const [righSideColor, setRightSideColor] = useState("#FFFFFF");
  const [leftSideColor, setLeftSideColor] = useState("#FFFFFF");



  const arr = [
    "LEFT_Image",
    "LEFT_TONGUE_Image",
    "RIGHT_Image",
    "RIGHT_TONGUE_Image",
    "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2",
    "RIGHT_SHOE_STITCH_Image-2",
    "RIGHT_SHOE_EYELETS_Image-2",
    "RIGHT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2",
    "RIGHT_SHOE_STITCH_Image-2",
    "RIGHT_SHOE_EYELETS_Image-2",
    "RIGHT_SHOE_CUTTING_LINE_Image-2",
    "RIGHT_SHOE_FOLDING_LINE_Image-2",
    "RIGHT_SHOE_TONGUE_CUTTING_LINE_Image-2",
    "RIGHT_SHOE_TONGUE_FOLDING_LINE_Image-2",
    "LEFT_SHOE_FOLDING_OUTSOLE_STRAP_Image-2",
    "LEFT_SHOE_STITCH_Image-2",
    "LEFT_SHOE_EYELETS_Image-2",
    "LEFT_SHOECUTTING_LINE_Image-2",
    "LEFT_SHOE_FOLDING_LINE_Image-2",
    "LEFT_TONGUE_CUTTING_LINE_Image-2",
    "LEFT_TONGUE_FOLDING_LINE_Image-2",
    "LEFT",
    "LEFT_TONGUE",
    "IN",
    "OUT",
    "RIGHT",
    "RIGHT_TONGUE",
    "IN-2",
    "OUT-2",
    "CUTTING_FOLDING_LINE-RECTANGLE_Image-2",
    "CUTTING_LINE",
    "FOLDING_OUTSOLE_STRAP-RECTANGLE_Image-2",
    "FOLDING_OUTSOLE_STRAP",
    "STITCHES_EYELETS-RECTANGLE_Image-2",
    "STITCHES_EYELETS",
  ];

  const parts = [
    "LEFT_Image",
    "LEFT_TONGUE_Image",
    "RIGHT_Image",
    "RIGHT_TONGUE_Image",
  ];



  const handleColorChange = (newColor: string) => {
    setProductColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if (parts.includes((obj as any).id)) {
            obj.set("fill", newColor);
          }
        }
      });

      // console.log(canvas,"Can")
      canvas?.renderAll();
    }

    canvas?.renderAll();
  };

  const handleLeftTongueColor = (newColor: string) => {
    setLeftTongueColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if ((obj as any).id === "LEFT_TONGUE_Image") {
            obj.set("fill", newColor);
          }
        }
      });

      // console.log(canvas,"Can")
      canvas?.renderAll();
    }
  };

  const handleRightTongueColor = (newColor: string) => {
    setRightTongueColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if ((obj as any).id === "RIGHT_TONGUE_Image") {
            obj.set("fill", newColor);
          }
        }
      });

      // console.log(canvas,"Can")
      canvas?.renderAll();
    }
  };

  const handleRightSideColor=(newColor:string)=>{
    setRightSideColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if ((obj as any).id === "RIGHT_Image") {
            obj.set("fill", newColor);
          }
        }
      });

      // console.log(canvas,"Can")
      canvas?.renderAll();
    }
  }

  const handleLeftSideColor=(newColor:string)=>{
    setLeftSideColor(newColor);

    if (bgSvg instanceof fabric.Group) {
      bgSvg._objects.forEach((obj: fabric.Object) => {
        if (obj) {
          if ((obj as any).id === "LEFT_Image") {
            obj.set("fill", newColor);
          }
        }
      });

      canvas?.renderAll();
    }
  }
  return (
    <div className="container image-panel ">
      <div className="d-flex justify-content-center border-bottom pt-4">
        <h5 className="fw-light">COLORS</h5>
      </div>
      <div className="color-panel">
      <div className=" px-3 py-2 ">
        <div className="justify-content-center d-flex mt-3">
          <button
            className="btn btn-outline-secondary color-picker-button "
            type="button"
            style={{ backgroundColor: "white", boxShadow: "none" }}
          >
            <ColorPicker
              color={productColor}
              onChange={(event) => handleColorChange(event)}
            />
          </button>
        </div>
        <div className="justify-content-center d-flex">Product Color</div>
      </div>
      <div className=" px-3 py-2 ">
        <div className="justify-content-center d-flex mt-3">
          <button
            className="btn btn-outline-secondary color-picker-button "
            type="button"
            style={{ backgroundColor: "white", boxShadow: "none" }}
          >
            <ColorPicker
              color={leftTongueColor}
              onChange={(event) => handleLeftTongueColor(event)}
            />
          </button>
        </div>
        <div className="justify-content-center d-flex">Left Tongue</div>
      </div>
      <div className=" px-3 py-2 ">
        <div className="justify-content-center d-flex mt-3">
          <button
            className="btn btn-outline-secondary color-picker-button "
            type="button"
            style={{ backgroundColor: "white", boxShadow: "none" }}
          >
            <ColorPicker
              color={rightongueColor}
              onChange={(event) => handleRightTongueColor(event)}
            />
          </button>
        </div>
        <div className="justify-content-center d-flex">Right Tongue</div>
      </div>
      <div className=" px-3 py-2 ">
        <div className="justify-content-center d-flex mt-3">
          <button
            className="btn btn-outline-secondary color-picker-button "
            type="button"
            style={{ backgroundColor: "white", boxShadow: "none" }}
          >
            <ColorPicker
              color={righSideColor}
              onChange={(event) => handleRightSideColor(event)}
            />
          </button>
        </div>
        <div className="justify-content-center d-flex">Right Side</div>
      </div>
      <div className=" px-3 py-2 ">
        <div className="justify-content-center d-flex mt-3">
          <button
            className="btn btn-outline-secondary color-picker-button "
            type="button"
            style={{ backgroundColor: "white", boxShadow: "none" }}
          >
            <ColorPicker
              color={leftSideColor}
              onChange={(event) => handleLeftSideColor(event)}
            />
          </button>
        </div>
        <div className="justify-content-center d-flex">Left Side</div>
      </div>
      </div>
    </div>
  );
};

export default ColorPanel;
