import axios from 'axios';

type PixabayImage = {
  id: number;
  webformatURL: string;
  tags: string;
};

type PexelsImage = {
  id: number;
  src: {
    original: string;
    medium: string
  };
  tags: string;
};

class ImageLibraries {
  private imageLibraryType: 'pixabay' | 'pexels';

  constructor(imageLibraryType: 'pixabay' | 'pexels') {
    this.imageLibraryType = imageLibraryType;
  }

  private async searchPixabayImages(query: string): Promise<PixabayImage[]> {
    const response = await axios.get(
      `https://pixabay.com/api/?key=${process.env.REACT_APP_MB_PIXABAY_API_KEY}&q=${encodeURIComponent(query)}`
    );
    return response.data.hits;
  }

  private async searchPexelsImages(query: string): Promise<PexelsImage[]> {
    const response = await axios.get(
      `https://api.pexels.com/v1/search?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `${process.env.REACT_APP_MB_PEXELS_API_KEY}`,
        },
      }
    );
    return response.data.photos;
  }

  public async searchImages(query: string): Promise<(PixabayImage | PexelsImage)[]> {
    let images: (PixabayImage | PexelsImage)[];
    if (this.imageLibraryType === 'pixabay') {
      images = await this.searchPixabayImages(query);
    } else {
      images = await this.searchPexelsImages(query);
    }
    return images;
  }
}

export type { PixabayImage, PexelsImage };
export default ImageLibraries;
