import { fabric } from "fabric";

type ImageOption = {
  left?: number;
  top?: number;
  originX?: string;
  originY?: string;
};

export const addImageFromUrl = (
  canvas: fabric.Canvas,
  imageUrl: string,
  options?: ImageOption
) => {
  fabric.Image.fromURL(
    imageUrl,
    (img) => {
      const defaultOptions: ImageOption = {
        left: 0,
        top: 100,
        originX: "center",
        originY: "center",
      };

      const mergedOptions = { ...defaultOptions, ...options };

      img.set({
        left: mergedOptions.left,
        top: mergedOptions.top,
      });

      canvas.add(img);
      canvas.requestRenderAll();
    },
    { crossOrigin: "anonymous" }
  );
};

export const setArtworkColor = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path,
  color: string
) => {
  if (activeObject instanceof fabric.Path) {
    activeObject.set("fill", color);
    canvas?.renderAll();
  }
};

export const flipImageX = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  if (activeObject instanceof fabric.Path) {
    activeObject.set("flipX", !activeObject.flipX);
    canvas.renderAll();
  }
};

export const flipImageY = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  if (activeObject instanceof fabric.Path) {
    activeObject.set("flipY", !activeObject.flipY);
    canvas.renderAll();
  }
};

export const rotateLeft = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  if (activeObject.angle || activeObject.angle === 0) {
    activeObject.set("angle", activeObject.angle - 20);
    canvas.renderAll();
  }
};

export const rotateRight = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  if (activeObject.angle || activeObject.angle === 0) {
    activeObject.set("angle", activeObject.angle + 20);
    canvas.renderAll();
  }
};

export const cloneObject = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  if (activeObject) {
    activeObject.clone(function (obj) {
      var vobj = obj;
      if (vobj && activeObject.top !== undefined) {
        vobj.set({
          top: activeObject.top + 30,
        });
        canvas.add(vobj);
        canvas.renderAll();
        canvas.calcOffset();
      }
    });
    canvas.renderAll();
  }
};

export const moveBackward = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  canvas.sendBackwards(activeObject);
  canvas.renderAll();
};
export const moveForward = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  canvas.bringForward(activeObject);
  canvas.renderAll();
};

export const bringToFront = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  canvas.bringToFront(activeObject);
  canvas.renderAll();
};

export const sendToBack = (
  canvas: fabric.Canvas,
  activeObject: fabric.Path
) => {
  canvas.sendToBack(activeObject);
  canvas.renderAll();
};
