import React, { useState, useRef, useEffect, MutableRefObject } from "react";

import { fabric } from "fabric";
import CanvasEvents from "../library/canvasEvents";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripVertical,
  faAnglesLeft,
  faAnglesRight,
  faSearchPlus,
  faSearchMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Sneaker from "./leftSidebar/Sneaker";
import RightSideBar from "./RightSideBar";

interface SneakerProps {
  canvasEventsRef: MutableRefObject<CanvasEvents | null>;
  canvas: fabric.Canvas | null;
  isVisible: boolean;
  alertText: string | null;
  activeObject: fabric.Object | null;
  bgSvg: fabric.Object | fabric.Group | null;
  setCanvas: React.Dispatch<React.SetStateAction<fabric.Canvas | null>>;
  setBgSvg: React.Dispatch<
    React.SetStateAction<fabric.Object | fabric.Group | null>
  >;
  resetVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContainerWidth = ({
  canvas,
  isVisible,
  alertText,
  canvasEventsRef,
  activeObject,
  bgSvg,
  setCanvas,
  setBgSvg,
  resetVisibility,
}: SneakerProps) => {
  const centerAreaRef = useRef<HTMLDivElement | null>(null);
  const [centerAreaWidth, setCenterAreaWidth] = useState(0);
  const [zoom, setZoom] = useState(1); // initial zoom level is 100%
  const [rightSidebarWidth, setRightSidebarWidth] = useState(0); // Initial width for the right sidebar
  const [isResizing, setIsResizing] = useState(false); // Whether resizing is active
  const [isResizingLeft, setIsResizingLeft] = useState(true);
  const startX = useRef(0); // Starting position of the mouse
  const startWidth = useRef(0); // Starting width of the right sidebar

  // Mouse move handler to resize the right sidebar
  const handleMouseMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isResizing) {
      //   const newWidth = startWidth.current - (e.clientX - startX.current);
      //   setRightSidebarWidth(Math.max(0, Math.min(newWidth, 400)));

      let newWidth = startWidth.current - (e.clientX - startX.current);

      if (newWidth >= 0 && newWidth <= 250) {
        const targetWidth = newWidth <= 125 ? 0 : 250; // If it's closer to 0px, set to 0, else set to 200
        setRightSidebarWidth(targetWidth);
      } else {
        setRightSidebarWidth(Math.max(0, Math.min(newWidth, 800))); // Constrain between 0 and 400px
      }

      if (e.clientX <= startX.current) {
        setIsResizingLeft(true);
      } else {
        setIsResizingLeft(false);
      }
    }
  };

  // Mouse down handler to start resizing
  const handleMouseDown = (e) => {
    setIsResizing(true);
    startX.current = e.clientX;
    startWidth.current = rightSidebarWidth;

    if (e.clientX <= startX.current) {
      setIsResizingLeft(true);
    } else {
      setIsResizingLeft(false);
    }
  };

  // Mouse up handler to stop resizing
  const handleMouseUp = (e) => {
    e.stopPropagation();
    startX.current = e.clientX;
    startWidth.current = rightSidebarWidth;
    setIsResizing(false);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);

    setZoom((prevZoom) => {
      const newZoom = Math.min(prevZoom * 1.1, 3);
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom * 0.9, 0.1); // min 10%
      return newZoom;
    });
  };

  const handleDelete = () => {
    canvasEventsRef.current?.deleteSelectedObject();
  };

  useEffect(() => {
    const handleResize = () => {
      if (centerAreaRef.current)
        setCenterAreaWidth(centerAreaRef.current?.clientWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Adding event listeners for mouse move and mouse up to the document when resizing
  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="container1">
      {/* Center Area */}
      <div className="center-area1" ref={centerAreaRef}>
        <Sneaker
          zoom={zoom}
          canvas={canvas}
          setCanvas={setCanvas}
          setBgSvg={setBgSvg}
          resetVisibility={resetVisibility}
          isVisible={isVisible}
          alertText={alertText}
          centerAreaWidth={centerAreaRef.current?.clientWidth}
          centerAreaHeight={centerAreaRef.current?.clientHeight}
        />
      </div>

      {/* Right Sidebar with Resizer */}
      <div
        className="right-sidebar1"
        style={{
          display: `${
            rightSidebarWidth > 0 && rightSidebarWidth < 200 ? "none" : null
          }`,
          width: `${rightSidebarWidth}px`,
        }} // Dynamic width for the right sidebar
      >
        <RightSideBar
          canvas={canvas}
          bgSvg={bgSvg}
          resetVisibility={resetVisibility}
        />
        <div className="resize-handle" onMouseDown={handleMouseDown}>
          {isResizingLeft ? (
            <FontAwesomeIcon icon={faAnglesLeft} />
          ) : (
            <FontAwesomeIcon icon={faAnglesRight} />
          )}
        </div>
      </div>

      <div className="mb-5 position-fixed bottom-0 end-0">
        <ul style={{ listStyleType: "none" }}>
          <li>
            <button className="btn btn-light mr-1" onClick={handleZoomIn}>
              <FontAwesomeIcon
                icon={faSearchPlus}
                style={{ fontSize: "2rem" }}
              />
            </button>
          </li>
          <li>
            <button className="btn btn-light" onClick={handleZoomOut}>
              <FontAwesomeIcon
                icon={faSearchMinus}
                style={{ fontSize: "2rem" }}
              />
            </button>
          </li>
          <li>
            <button
              className="btn btn-light"
              onClick={handleDelete}
              disabled={!activeObject}
            >
              <FontAwesomeIcon icon={faTrash} style={{ fontSize: "2rem" }} />
            </button>
          </li>

          {/* <li>
              <button
                className="btn btn-light"
                onClick={toggleLight}
              >
                <FontAwesomeIcon icon={faLightbulb} style={{ fontSize: "2rem" }} />
              </button>
            </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ContainerWidth;
