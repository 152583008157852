import { fabric } from 'fabric';
import CanvasHistory from './canvasHistory';

class CanvasEvents {
  private canvas: fabric.Canvas;
  private setActiveObject: React.Dispatch<React.SetStateAction<fabric.Object | null>>;
  private canvasHistory: CanvasHistory;
  private activeObject: fabric.Object | null;

  constructor(
    canvas: fabric.Canvas,
    setActiveObject: React.Dispatch<React.SetStateAction<fabric.Object | null>>
  ) {
    this.canvas = canvas;
    this.activeObject = null;
    this.setActiveObject = setActiveObject;
    this.canvasHistory = new CanvasHistory(canvas, 100);
    this.canvas.on('selection:created', this.handleSelection);
    this.canvas.on('selection:updated', this.handleSelection);
    this.canvas.on('selection:cleared', this.handleClearSelection);

    // Events for History
    this.canvas.on('object:added', this.saveHistory);
    this.canvas.on('object:modified', this.saveHistory);
    this.canvas.on('object:removed', this.saveHistory);
   

    document.addEventListener('keydown', (event) => {
      
      if (event.ctrlKey && event.keyCode === 90) {
        this.handleUndo();
      } else if (event.ctrlKey && event.keyCode === 89) {
        this.handleRedo();
      } else if (event.key === 'Delete') {
        this.deleteSelectedObject();
      }
    });


  }

  handleSelection = (event: fabric.IEvent) => {
    this.activeObject = this.canvas.getActiveObject();
    if (this.activeObject) {
      this.setActiveObject(this.activeObject);
    }
  };

  handleClearSelection = (event: fabric.IEvent) => {
    this.setActiveObject(null);
  };

  saveHistory = (event: fabric.IEvent) => {

    if (this.canvasHistory.historyProcessing) {            
      return;
    }
    const currentState = this.canvasHistory.getCurrentState();
    const stateObj = this.canvas.getObjects();
    this.canvasHistory.saveHistoryItem(currentState, stateObj);
  };

  handleUndo() {
    this.canvasHistory.undo();
  }

  handleRedo() {
    this.canvasHistory.redo();
  }

  deleteSelectedObject = () => {
    if (this.canvas && this.activeObject) {
      this.canvas.remove(this.activeObject);
      this.activeObject = null;
      this.setActiveObject(null);
    }
  };


  destroy() {
    this.canvas.off('selection:created', this.handleSelection);
    this.canvas.off('selection:updated', this.handleSelection);
    this.canvas.off('selection:cleared', this.handleClearSelection);
    this.canvas.off('object:added', this.saveHistory);
    this.canvas.off('object:modified', this.saveHistory);
    this.canvas.off('object:removed', this.saveHistory);

  }
}

export default CanvasEvents;
